/** @format */

:root {
  --toastify-toast-width: 520px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: Poppins;
  --toastify-font-weight: 400;
  --toastify-z-index: 9999;

  --toastify-color-progress-light: linear-gradient(
    to right,
    #fff,
    #fff,
    #fff,
    #fff,
    #fff,
    #fff
  );
}

[aria-expanded="true"] .show-less,
[aria-expanded="false"] .show-more {
  display: block;
}

[aria-expanded="true"] .show-more,
[aria-expanded="false"] .show-less {
  display: none;
}

/* .show-more,
.show-less {
  transition: opacity 0.2s;
} */

@media (min-width: 992px) {
  .accordion-body {
    display: flex;
    justify-content: space-between;
  }
}
#createNewOrg {
  cursor: pointer;
}
#reportOrg {
  cursor: pointer;
}

.react-confirm-alert {
  width: 100% !important;
}

.react-confirm-alert-overlay {
  background: none;
}

.bootstrap-select li .text {
  margin-right: 0;
}

#selectableRowCheckbox:hover {
  cursor: pointer;
}

@media(min-width: 360px) and (max-width: 768px){
  
#AddA{
     
     width: 50% !important;

  }
 }

